import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../button/Button';
import Loader from '../loader/Loader';

const Login = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  let navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      (username === '' || password === '') &&
      (username.length < 3 || password.length < 3)
    ) {
      alert('Please enter username or password');
      return;
    }
    login();
  };

  const login = async () => {
    setShowLoader(true);
    const res = await fetch('https://yourhope.io/api/User/LoginUser', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
        LastLogIn: new Date(),
      }),
    });
    const data = await res.json();
    if (data) {
      localStorage.setItem('username', username);
      setUserName('');
      setPassword('');
      navigate('/engage');
    } else alert('Incorrect username or password');
    setShowLoader(false);
    console.log('data from server: ', data);
  };

  return (
    <div className='container'>
      <h2 className='center'>Welcome</h2>
      <form className='add-form'>
        <div className='form-control'>
          {/* <label>Username</label> */}
          <input
            type='text'
            placeholder='user name'
            value={username}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className='form-control'>
          {/* <label>Password</label> */}
          <input
            type='password'
            placeholder='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {/* <div className='form-control form-control-check'>
          <label>Set Reminder</label>
          <input
            type='checkbox'
            checked={reminder}
            value={reminder}
            onChange={(e) => setReminder(e.currentTarget.checked)}
          />
        </div> */}
        {showLoader ? (
          <Loader />
        ) : (
          <Button
            onClick={onSubmit}
            text='Login'
            txtColor='white'
            color='black'
            className='btn-block'
          />
        )}
        {/* <input type='submit' className='btn btn-block' value='Login' /> */}
      </form>
    </div>
  );
};

export default Login;
