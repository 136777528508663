import { useState } from 'react';
import Button from '../button/Button';

const AddCustomer = ({ addNewCustomer }) => {
  const [name, setName] = useState('');
  const [mobileNum, setmobileNum] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      (name === '' || mobileNum === '') &&
      (name.length < 3 || name.length < 3)
    ) {
      alert('Please enter name or mobile number');
      return;
    }
    addNewCustomer({ name: name, mobileNum: mobileNum });

    setName('');
    setmobileNum('');
  };

  return (
    <div>
      <div>
        <h3>Add Customer</h3>
        <form className='add-form'>
          <div className='form-control'>
            <input
              type='text'
              placeholder='Customer Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='form-control'>
            <input
              type='text'
              placeholder='Mobile number'
              value={mobileNum}
              onChange={(e) => setmobileNum(e.target.value)}
            />
          </div>

          {/* <input
            type='submit'
            className='btn btn-block'
            value='Add New Customer'
          /> */}
          <Button
            onClick={onSubmit}
            text='Add New Customer'
            txtColor='white'
            color='black'
            className='btn-block'
          />
        </form>
      </div>
    </div>
  );
};

export default AddCustomer;
