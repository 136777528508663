import './customer.css';
import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import Button from '../button/Button';

const Customer = ({ customer, onDelete, onGetDetails }) => {
  const [confirmModal, setConfirmModal] = useState(false);

  return (
    <div>
      {confirmModal && (
        <div className='customer confirmDelete'>
          <h3>
            Are you sure you want to remove {customer.FirstName}
            {' as a client?'}
          </h3>
          <div className='btnOptions'>
            <Button
              color='black'
              text='No'
              txtColor='white'
              onClick={() => setConfirmModal(!confirmModal)}
            />
            <Button
              color='red'
              text='Yes'
              txtColor='white'
              onClick={() => onDelete(customer)}
            />
          </div>
          {customer.ID && (
            <small className='floatRight'>
              Member since:&nbsp;
              {new Date(customer.DateAccountCreated).toLocaleDateString()}
            </small>
          )}
        </div>
      )}

      {!confirmModal && (
        <div className='customer'>
          <h3>
            {customer.FirstName}
            <FaTrashAlt
              onClick={() => setConfirmModal(!confirmModal)}
              style={{ color: 'red', cursor: 'pointer' }}
            />
          </h3>

          <p>{customer.CellPhoneNumber} </p>
          {customer.ID && (
            <small className='floatRight'>
              Member since:&nbsp;
              {new Date(customer.DateAccountCreated).toLocaleDateString()}
            </small>
          )}
        </div>
      )}
    </div>
  );
};

export default Customer;
