import Button from '../button/Button';

const EngageiOS = () => {
  return (
    <div className='contain'>
      <h1>Engage iOS Users</h1>

      <Button text='Test' txtColor='white' color='blue' />

      <button style={{ padding: 5, borderRadius: 6 }}>Test</button>
    </div>
  );
};

export default EngageiOS;
