import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './app/components/header/Header';
import Login from './app/components/login/Login';
import Engage from './app/components/engage/Engage';
import Dashboard from './app/components/dashboard/Dashboard';
import EngageiOS from './app/components/engage-ios/engage-ios';

function App() {
  return (
    <Router>
      <div className=''>
        <Header />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/engage' element={<Engage />} />
          <Route path='/ios' element={<EngageiOS />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
