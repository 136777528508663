import { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import Title from '../title/Title';
import List from '../list/List';
// import Customer from '../customer/Customer';
import { useNavigate } from 'react-router-dom';
import TextArea from '../textarea/TextArea';
import AddCustomer from '../addCustomer/AddCustomer';

const Engage = () => {
  const [customerList, setCustomerList] = useState([]);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (['', undefined, null].includes(localStorage.getItem('username'))) {
      navigate('/');
      return;
    }
    const getUserData = async () => {
      const userData = await fetchUserData();
      localStorage.setItem('ClientID', userData.ClientID);
      localStorage.setItem('ProviderID', userData.ID);

      getCustomerList();
    };
    getUserData();

    const getCustomerList = async () => {
      const customerList = await fetchCustomerData();
      console.log(customerList.sort());
      debugger;
      setCustomerList(customerList.sort());
    };
  }, []);

  const fetchUserData = async () => {
    const res = await fetch('https://yourhope.io/api/User/PostCurrentUser', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ UserName: localStorage.getItem('username') }),
    });
    const data = await res.json();
    return data;
  };

  const fetchCustomerData = async () => {
    const res = await fetch('https://yourhope.io/api/Patient/PostPatientList', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ clientID: localStorage.getItem('ClientID') }),
    });
    const customerData = await res.json();
    return customerData;
  };

  const onDelete = async (customer) => {
    const res = await fetch(
      'https://yourhope.io/api/Patient/PostDeletePatient',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(customer),
      }
    );
    const response = await res.json();
    console.log(response);
    if (response) {
      const customerList = await fetchCustomerData();
      setCustomerList(customerList);
    }
  };

  const sendMessage = async ({ message, selectedImage }) => {
    console.log(message);
    console.log(selectedImage);

    const messageObj = {
      PatientID: +localStorage.getItem('ClientID'),
      PatientEmail: message,
      DateOutReached: getCurrentDateTime(),
      OutReachedByProviderID: +localStorage.getItem('ProviderID'),
      ProviderNotes: selectedImage === '' ? null : selectedImage,
    };
    // alert(
    //   '⚠️ Account Past Due. Last payment recieved July 2023. Please contact James at (407) 236-6630 to enable your account. \n\nCuenta Vencida. Último pago recibido en julio de 2023. Comuníquese con James al (407) 236-6630 para reactivar su cuenta.'
    // );
    // return;
    console.log('body: ', messageObj);

    const res = await fetch(
      'https://yourhope.io/api/Notifications/PostOutReachMembers',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(messageObj),
      }
    );
    const response = await res.json();
    console.log(response);
    if (response) {
      alert('Success! All Your clients have been outreached.');
    }
  };

  const addNewCustomer = async (customer) => {
    const customerObj = {
      firstName: customer.name,
      CellPhoneNumber: customer.mobileNum,
      DateAccountCreated: getCurrentDateTime(),
      ClientID: +localStorage.getItem('ClientID'),
      AccountStatus: +localStorage.getItem('ProviderID'),
      LastName: 'A',
      MiddleName: 'n/a',
      DOB: getCurrentDateTime(),
      Address: 'N/A',
      City: 'Kissimmee',
      State: 'FL',
      ZipCode: '34746',
      gender: 'male',
      WorkPhone: '407-000-000',
      MartialStatus: 'Single',
      Email: 'james@react.com',
      Longitude: 0,
      Latitude: 0,
    };

    console.log(customerObj);

    const res = await fetch('https://yourhope.io/api/Patient/PostNewPatient', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(customerObj),
    });
    const response = await res.json();
    console.log(response);
    if (response) {
      const customerList = await fetchCustomerData();
      setCustomerList(customerList);
      setShowAddCustomer(false);
    }
  };

  const getCurrentDateTime = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = mm + '/' + dd + '/' + yyyy;

    var time = new Date();
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var currentDate = today + ' ' + strTime;

    return currentDate;
  };

  return (
    <div>
      <Title
        text='Engage'
        showButton={true}
        showAdd={showAddCustomer}
        btnText='Add New Customer'
        onAdd={() => setShowAddCustomer(!showAddCustomer)}
      />
      {showAddCustomer && (
        <div className='contain'>
          <AddCustomer addNewCustomer={addNewCustomer} />
        </div>
      )}

      <TextArea
        amountOfCustomers={customerList.length}
        onSendMessage={sendMessage}
      />
      {customerList.length > 0 ? (
        <List list={customerList} onDelete={onDelete} />
      ) : (
        <p className='contain'>Add a customer to see them here.</p>
      )}
    </div>
  );
};

export default Engage;
