import Customer from '../customer/Customer';

const List = ({ list, onDelete, onGetDetails }) => {
  return (
    <div className='contain'>
      <small>{list.length} customers</small>
      {list.map((customer) => (
        <Customer
          key={customer.ID}
          customer={customer}
          onDelete={onDelete}
          onGetDetails={onGetDetails}
        />
      ))}
    </div>
  );
};

export default List;
