import S3FileUpload from 'react-s3';
import { useState } from 'react';

import Loader from '../loader/Loader';
import Button from '../button/Button';

const Upload = ({ onSelectedImage, pro }) => {
  const [loader, setLoader] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const standardLogo =
    'https://notifygridmedia.s3.us-east-2.amazonaws.com/img/tonyTouch.jpg';

  const [standardLogoSelected, setStandardLogoSelected] = useState(false);
  const [uploadedImageSelected, setUploadedImageSelected] = useState(false);
  const uploadImage = async (file) => {
    setLoader(true);
    setUploadedImage('');
    file = file.target.files[0];

    const folder = 'img/';

    const config = {
      bucketName: 'notifygridmedia',
      dirName: folder + file.name,
      region: 'us-east-2',
      accessKeyId: 'AKIAIWVQ75HBWTYBIKNQ',
      secretAccessKey: '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',
    };

    // console.log('file: ', file);

    S3FileUpload.uploadFile(file, config)
      .then((result) => {
        // console.log('result from AWS: ', result);
        setUploadedImage(result.location);
        setLoader(false);
      })
      .catch((err) => {
        console.log('something went wrong: ', err);
      });
  };

  const determineImage = (image) => {
    if (!pro) return;
    if (
      image.target.currentSrc ===
      'https://notifygridmedia.s3.us-east-2.amazonaws.com/img/tonyTouch.jpg'
    ) {
      setStandardLogoSelected(true);
      setUploadedImageSelected(false);
    } else {
      setUploadedImageSelected(true);
      setStandardLogoSelected(false);
    }
    onSelectedImage(image.target.currentSrc);
  };

  const setToDefault = (e) => {
    e.preventDefault();
    setUploadedImageSelected(false);
    setStandardLogoSelected(false);
    onSelectedImage('');
  };

  return (
    <div className='addMargin'>
      {!loader ? (
        <div>
          <div>
            <h4 style={{ color: 'gray' }}>Select a picture</h4>
            {uploadedImage !== '' && (
              <img
                onClick={determineImage}
                className={`photo ${uploadedImageSelected ? 'selected' : ''}`}
                src={uploadedImage}
                alt='uploaded'
              />
            )}
            <img
              disabled={!pro}
              onClick={determineImage}
              className={`photo ${standardLogoSelected ? 'selected' : ''}`}
              src={standardLogo}
              alt='default'
            />
            {(standardLogoSelected || uploadedImageSelected) && (
              <div className='addMargin'>
                <Button text='do not include picture' onClick={setToDefault} />
              </div>
            )}
          </div>

          <h3 style={{ color: 'gray' }}>Upload Picture</h3>
          <input disabled={!pro} type='file' onChange={uploadImage} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Upload;
