import { Link } from 'react-router-dom';
import './header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ng from '../../../imgs/clients/ng.png';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const logOut = () => {
    console.log(localStorage.getItem('username'));
    localStorage.clear();
    console.log(localStorage.getItem('username'));
    window.location.href = 'https://notifygrid.com/';
  };

  const goTo = (path) => {
    navigate(path);
  };

  return (
    <div className='topnav'>
      <div className='nav'>
        <Link className='active brand' to='/'>
          <img src={ng} className='ng-logo' alt='ng logo' />
          {/* <h2>NotifyGrid</h2> */}
        </Link>
        {location.pathname === '/' && (
          <Link className='signIn' to='/login'>
            Login
          </Link>
        )}
        {![null, undefined].includes(localStorage.getItem('username')) && (
          <div>
            <Link className='signIn' onClick={logOut}>
              Logout
            </Link>

            <Link
              to='/dashboard'
              className='signIn'
              onClick={() => goTo('/dashboard')}
            >
              Dashboard
            </Link>
            <Link
              to='/engage'
              className='signIn'
              onClick={() => goTo('/engage')}
            >
              Engage
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
