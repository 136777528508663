import { useState } from 'react';
import Upload from '../upload/upload';
import Button from '../button/Button';

const TextArea = ({ onSendMessage, amountOfCustomers }) => {
  const [message, setMessage] = useState('');
  const [showNoMessage, setErrorMessage] = useState(false);
  const [selectedImage, setImageUrl] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if ([0, 1, 2, 3, 4, 5].includes(message.length)) {
      setErrorMessage(true);
      return;
    }
    onSendMessage({ message, selectedImage });
    setMessage('');
    setImageUrl('');
  };

  const setImage = (imageUrl) => {
    setImageUrl(imageUrl);
  };

  const upgrade = () => {
    alert('Call James to upgrade your plan today: 407-236-6630');
  };

  return (
    <div className='contain'>
      <form className='engagement-form'>
        <div className='form-control'>
          {/* <label>Engage your customers</label> */}
          <textarea
            type='text'
            placeholder='Engage your customer list to bring them through your door.'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows='10'
          ></textarea>
          {showNoMessage && (
            <p className='errorText'>
              Do you really want to text {amountOfCustomers} customers nothing?
              Type an engaging message to bring them through your door!
            </p>
          )}
        </div>
        <p>Want to send a picture with your messages?</p>
        <Button
          txtColor='#3399ff'
          color='white'
          onClick={upgrade}
          textDecoration='underline'
          text='Upgrade your plan.'
        />

        <Upload onSelectedImage={setImage} pro={false} />
        {/* <input
          type='submit'
          className='btn'
          value='Send Mass Text 📲'
        /> */}
        <Button
          onClick={onSubmit}
          text='Send Mass Text 📲'
          txtColor='white'
          color='3399ff'
          className='btn-blue'
        />
      </form>
    </div>
  );
};

export default TextArea;
