import './dashboard.css';
import { useState, useEffect } from 'react';
import Title from '../title/Title';

const Dashboard = ({ customer, onDelete, onGetDetails }) => {
  const [monthlySMS, setMonthlySMS] = useState('loading...');
  const [lifetimeSMS, setLifetimeSMS] = useState('loading...');

  useEffect(() => {
    getCustomerSMSStats();
  }, []);

  const getCustomerSMSStats = async () => {
    const res = await fetch(
      'https://yourhope.io/api/Notifications/PostMonthlySMS',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ clientId: localStorage.getItem('ClientID') }),
      }
    );

    const data = await res.json();

    if (data) {
      setMonthlySMS(data.toLocaleString());
      getLifeTimeSMSSent();
    } else {
      console.log(data);
    }
  };

  const getLifeTimeSMSSent = async () => {
    const res = await fetch(
      'https://yourhope.io/api/Notifications/PostLifeTimeSMS',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ clientId: localStorage.getItem('ClientID') }),
      }
    );

    const data = await res.json();

    if (data) {
      setLifetimeSMS(data.toLocaleString());
    } else {
      console.log(data);
    }
  };

  // geTotalMessagesForTheLifeTime(clientId) {
  //   return this.http.post<any>('https://yourhope.io/api/Notifications/PostLifeTimeSMS', clientId);
  // }

  return (
    <div>
      <Title text='Dashboard' showButton={false} showAdd={false} />
      <div className='contain'>
        <div className='flex-row'>
          <div>
            <h1>{monthlySMS}</h1>
            <p>Text messages sent this month.</p>
          </div>
          <div>
            <h1>{lifetimeSMS}</h1>
            <p>Total text messages sent with NotifyGrid.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
