import Button from '../button/Button';

const Title = ({ text, showButton, showAdd, btnText, onAdd }) => {
  return (
    <div className='contain'>
      <header className='header'>
        <h2>{text}</h2>
        {showButton && (
          <Button
            text={showAdd ? 'Close' : 'Add New Customer'}
            txtColor={showAdd ? 'white' : 'white'}
            onClick={onAdd}
            color={showAdd ? 'red' : 'black'}
          />
        )}
      </header>
    </div>
  );
};

export default Title;
