import PropTypes from 'prop-types';

const Button = ({
  color,
  text,
  txtColor,
  onClick,
  className,
  textDecoration,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: color,
        color: txtColor,
        textDecoration: textDecoration,
      }}
      className={`btn ${className !== '' ? className : ''}`}
    >
      {text}
    </button>
  );
};

Button.defaultProps = {
  color: 'steelblue',
  className: '',
  textDecoration: 'none',
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Button;
