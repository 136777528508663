import './loader.css';
import { FaCircleNotch } from 'react-icons/fa';

const Loader = () => {
  return (
    <div className='containerLoader'>
      <div className='box'>
        <FaCircleNotch />
      </div>
    </div>
  );
};

export default Loader;
